import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const requireAuth = (to, from, next) => {
  const path = to.path;
  const accessToken = localStorage.getItem("accessToken");

  // accessToken 값이 비어 있지 않으면 사용자가 인증되었으므로 다음 라우트로 이동
  // accessToken 값이 비어 있으면 사용자가 인증되지 않았으므로 로그인 페이지로 리다이렉션
  if (accessToken) {
    next();
  } else {
    // 로그인 성공 시 바로 이동
    localStorage.setItem("returnTo", path);
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/admin/login.vue"),
  },
  {
    path: "/admin",
    redirect: "/admin/inquiry",
    name: "admin",
    component: () => import("@/views/admin/AdminContainer.vue"),
    beforeEnter: requireAuth,
    children: [
      {
        path: "addBoard",
        name: "adminBoard",
        component: () => import("@/views/admin/AddBoard.vue"),
      },
      {
        path: "inquiry",
        name: "inquiry",
        component: () => import("@/views/admin/Inquiry.vue"),
      },
      {
        path: "inquiry/:seq",
        name: "inquiryDetail",
        component: () => import("@/views/admin/InquiryDetail.vue"),
      },
      {
        path: "setDesign",
        name: "designModify",
        component: () => import("@/views/admin/SetDesign.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/container.vue"),
    children: [
      {
        path: "",
        name: "main",
        component: () => import("@/views/main.vue"),
      },
      {
        path: ":category",
        name: "postList",
        component: () => import("@/views/categoryView.vue"),
      },
      {
        path: ":category/view/:postSeq",
        name: "contentsView",
        component: () => import("@/views/ContentsView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log(to);
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      console.log(savedPosition);
      // return savedPosition;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 100);
      });
    }

    return { x: 0, y: 0 };
  },
});

export default router;
