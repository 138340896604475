import axiosApi from "axios";
// import store from "../store";

// const axios = axiosApi.create({
//   baseURL: process.env.VUE_APP_API_ENDPOINT,
//   headers: {
//     // Accept: "application/json",
//     Authorization: localStorage.getItem("accessToken"),
//   },
// });

// 첫 화면에서 header안에 Autorization을 불러오지 못하는 오류 발생
// interceptor 활용해 request 요청 시 헤더에 값을 넣어줌
const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      Authorization: localStorage.getItem("accessToken"),
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
